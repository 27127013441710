var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-7 pb-11"},[_c('h1',{staticClass:"text-h4 mb-5"},[_vm._v(_vm._s(_vm._f("localize")('companies')))]),_c('div',{staticClass:"white elevation-2 rounded"},[_c('v-row',{staticClass:"ma-0 mb-1"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"background-color":_vm.search ? 'serenade' : '',"prepend-inner-icon":"mdi-magnify","outlined":"","hide-details":"","dense":"","clearable":"","placeholder":_vm._f("localize")('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"to":{name: 'create-company'},"color":"primary","depressed":"","rounded":""}},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"25","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm._f("localize")('createCompany'))+" ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.companiesList,"loading":_vm.loading,"loader-height":"2","sort-by":_vm.defaultSortBy,"sort-desc":_vm.defaultSortDesc,"options":_vm.options,"server-items-length":_vm.totalItems,"no-data-text":_vm._f("localize")('noDataAvailable'),"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"black--text",attrs:{"to":{name: 'create-company', query: {company_id: item.id}}}},[_vm._v(_vm._s(item.title)+" ")])]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(_vm.deleteQueue.includes(item.id))?_c('v-progress-circular',{attrs:{"indeterminate":"","width":1,"size":20}}):_c('v-menu',{attrs:{"left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"45","tile":""}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editCompany(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('edit')))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDeleteCompany(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('delete')))])],1)],1)],1)]}}])}),_c('v-pagination',{staticClass:"py-6",attrs:{"color":"primary","length":_vm.totalPages,"total-visible":7,"disabled":_vm.loading},on:{"input":function($event){_vm.companiesList = [];_vm.getCompanies();}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('SimpleDialog',{ref:"deleteComapnyDialog",attrs:{"title":_vm._f("localize")('deleteCompanyTitle'),"text":_vm._f("localize")('deleteCompanyText'),"accept-text":_vm._f("localize")('delete'),"decline-text":_vm._f("localize")('back')},on:{"decline":_vm.cancelDeleteCompany,"accept":_vm.deleteCompany}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }