<template>
  <v-container class="pt-7 pb-11">
    <h1 class="text-h4 mb-5">{{ 'companies'|localize }}</h1>
    <div class="white elevation-2 rounded">
      <v-row class="ma-0 mb-1">
        <v-col cols="4">
          <v-text-field
            :background-color="search ? 'serenade' : ''"
            v-model.trim="search"
            prepend-inner-icon="mdi-magnify"
            outlined
            hide-details
            dense
            clearable
            :placeholder="'search'|localize"
          />
        </v-col>
        <v-spacer/>
        <v-col cols="auto">
          <v-btn :to="{name: 'create-company'}" color="primary" depressed rounded>
            <v-icon size="25" left class="mr-3">mdi-plus</v-icon>
            {{ 'createCompany'|localize }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="companiesList"
        :loading="loading"
        loader-height="2"
        :sort-by="defaultSortBy"
        :sort-desc="defaultSortDesc"
        :options.sync="options"
        :server-items-length="totalItems"
        :no-data-text="'noDataAvailable'|localize"
        hide-default-footer
      >
        <template v-slot:item.title="{ item }">
          <router-link
            class="black--text" :to="{name: 'create-company', query: {company_id: item.id}}"
          >{{ item.title }}
          </router-link>
        </template>
        <template v-slot:item.settings="{ item }">
          <v-progress-circular
            v-if="deleteQueue.includes(item.id)"
            indeterminate
            :width="1"
            :size="20"
          />
          <v-menu v-else left transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on" size="45" tile>
                <v-icon size="20">mdi-dots-vertical</v-icon>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item link @click="editCompany(item)">
                <v-list-item-title>{{ 'edit'|localize }}</v-list-item-title>
              </v-list-item>
            <v-list-item link @click="openDeleteCompany(item)">
              <v-list-item-title>{{ 'delete'|localize }}</v-list-item-title>
            </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-pagination
        color="primary"
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        class="py-6"
        :disabled="loading"
        @input="companiesList = [];getCompanies();"
      />
    </div>
    <SimpleDialog
      ref="deleteComapnyDialog"
      :title="'deleteCompanyTitle'|localize"
      :text="'deleteCompanyText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @decline="cancelDeleteCompany"
      @accept="deleteCompany"
    />
  </v-container>
</template>

<script>
  import localize from '@/filters/localize';
  import methods from '@/helpers/methods';
  import api from '@/api/api';
  import SimpleDialog from '@/components/common/SimpleDialog';

  export default {
    name: 'AdminCompanies',
    components: {SimpleDialog},

    data() {
      return {
        loading: true,
        defaultSortBy: 'title',
        defaultSortDesc: true,
        debouncedSearch: '',
        debounceTimeout: null,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        companiesList: [],
        deleteQueue: [],
        deleteCompanyId: null,
      };
    },

    watch: {
      options: {
        handler() {
          this.clearCompaniesList();
          this.getCompanies();
        },
        deep: true,
      },
      search() {
        this.clearCompaniesList();
        this.getCompanies();
      },
    },

    computed: {
      headers() {
        return [
          {text: localize('name'), value: 'title', sortable: true},
          {text: localize('phoneNumber'), value: 'phone', sortable: false},
          {text: localize('contactPersonEmails'), value: 'emails', sortable: false, width: '50%'},
          {text: '', value: 'settings', sortable: false, width: 50, align: 'center'},
        ];
      },
      search: {
        get() {
          return this.debouncedSearch
        },
        set(val) {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debouncedSearch = val
          }, 500)
        }
      },
    },

    methods: {
      openDeleteCompany(company) {
        this.$refs.deleteComapnyDialog.open();
        this.deleteCompanyId = company.id;
      },
      cancelDeleteCompany() {
        this.deleteCompanyId = null;
      },
      async deleteCompany() {
        let companyId = this.deleteCompanyId;
        this.deleteCompanyId = null;
        this.deleteQueue.push(companyId)
        try {
          await api.deleteCompany(companyId);
          let companiesData = await api.getAdminCompanies(this.queryData());
          this.setCompaniesData(companiesData);
          this.deleteQueue = this.deleteQueue.filter(queueId => queueId !== companyId)
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      clearCompaniesList() {
        this.companiesList = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      setCompaniesData(companiesData) {
        this.companiesList = companiesData.data.data;
        this.totalPages = companiesData.data.pagination['total_page'];
        this.totalItems = companiesData.data.pagination['total_items'];
      },
      async getCompanies() {
        try {
          this.loading = true;
          let companiesData = await api.getAdminCompanies(this.queryData());
          this.setCompaniesData(companiesData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      queryData() {
        let queryData = {};
        queryData['per_page'] = this.itemsPerPage;
        queryData['page'] = this.currentPage;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        this.search && (queryData['search'] = this.search);
        return queryData;
      },
      editCompany(request) {
        this.$router.push({name: 'create-company', query: {company_id: request.id}});
      }
    }
  }
</script>
